<template>
  <div>
    500
  </div>
</template>

<script>
  export default {
    name: "Page500"
  }
</script>

<style scoped>

</style>
